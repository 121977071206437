var seeMore = function () {
    document.querySelectorAll('.awards__btn .c-btn').forEach(function (element) {
        element.addEventListener('click', function (ev) {
            ev.preventDefault();
            var el = ev.currentTarget,
                elParentNode = el.closest('.awards');
            elParentNode.querySelector('.awards__list ul.hide').style.display = 'flex';
            var gradientElement = elParentNode.querySelector('.awards__list.gradient');
            if (gradientElement) {
                gradientElement.classList.remove('gradient');
            }
            el.style.display = 'none';
        });
    });
};

seeMore();

// Publications button
var button = document.querySelector('.publications--seeMore');
var list = document.querySelector('.publications__list .hide');

if (button) {
    button.addEventListener('click', function(event) {
        event.preventDefault();
        list.style.display = 'block';
        button.style.visibility = 'hidden';

        // var scrollToOptions = {
        //   top: window.scrollY + 350,
        //   behavior: 'smooth'
        // };
        // window.scrollTo(scrollToOptions);
    });
}
